import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GetOrCreateResponse, ResidentialService } from '../../../core/services/residential.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import {catchError, debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap} from 'rxjs/operators';
import { SmartyService } from '../smarty.service';
import {EMPTY, merge, Observable, of, Subject, Subscription} from 'rxjs';
import {FastAPIService} from '../fastapi.service';
import {environment} from '@environment';
import {HttpErrorResponse} from '@angular/common/http';

export interface AddressesSettings {
  primaryProvider: string;
  fastAPIBase: string;
  fallbackToSmarty: boolean;
}

@Component({
  selector: 'ui-address-smarty',
  templateUrl: './ui-address-smarty.component.html',
  styleUrls: ['./ui-address-smarty.component.scss']
})
export class UiAddressSmartyComponent implements OnInit, OnDestroy {
  readonly PARTIAL_SEARCH_ERROR_CODE = 406;
  readonly SEARCH_MIN_LENGTH = 6;

  @Input() form: FormGroup;
  @Output() setBuildingsFromAddress = new EventEmitter();
  @Output() setUnitsFromAddress = new EventEmitter();
  @Output() setAccountId = new EventEmitter();
  @Output() setCommunity = new EventEmitter();

  addresses = [];
  subscription: Subscription = new Subscription();
  loadingAddress = false;
  showAddressHint = false;

  settings: AddressesSettings;
  selectedProvider: string;
  originalSelectedProvider: string;

  continueEnteringHint = false;


  retryFallback$ = new Subject<boolean>();

  constructor(private residentialService: ResidentialService,
              private smartyService: SmartyService,
              private fastAPIService: FastAPIService) {
    this.settings = environment.addressesSearch;
    this.selectedProvider = this.settings.primaryProvider;
    this.originalSelectedProvider = this.settings.primaryProvider;
  }

  ngOnInit() {
    this.onAddressChanges();
  }

  onAddressChanges() {
    this.addresses = [];

    const sub = merge(
      this.form.get('address').valueChanges,
      this.retryFallback$
    ).pipe(
      distinctUntilChanged(),
      debounceTime(800),
      switchMap(() => of(this.form.get('address').value)),
      filter(search => search && typeof search === 'string' && search.length > 0),
      filter((term: string) => {
        const isValid = term.length >= this.SEARCH_MIN_LENGTH;
        this.continueEnteringHint = !isValid;
        return isValid;
      }),
    ).subscribe(search => {
        this.loadingAddress = true;

        this.lookupAddresses(search).pipe(
          tap(() => {
            this.showAddressHint = false;
            this.continueEnteringHint = false;
          }),
          finalize(() => {
            this.loadingAddress = false;
          }),
          catchError((err) => {
            this.addresses = [];
            this.showAddressHint = false;
            this.continueEnteringHint = err?.originError?.status === this.PARTIAL_SEARCH_ERROR_CODE;
            return EMPTY;
          })
        ).subscribe((res) => {
          const addresses = res && res.length > 0 ? res : [];
          if (addresses) {
            addresses.forEach(address => { address.full = this.buildAddress(address) })
          }

          this.addresses = addresses;
          this.showAddressHint = this.addresses.length === 0;

          if (this.addresses.length === 0
            && this.selectedProvider === 'fastapi'
            && this.settings.fallbackToSmarty) {
            this.selectedProvider = 'smarty';
            this.retryFallback$.next(true);
          } else if (this.selectedProvider !== this.originalSelectedProvider) {
            this.selectedProvider = this.originalSelectedProvider;
          }
        });
    });

    this.subscription.add(sub);
  }

  lookupAddresses(searchString: string, fallbackSmarty = false): Observable<any> {
    if (!fallbackSmarty && this.selectedProvider === 'fastapi') {
      return this.fastAPIService.lookup(searchString);
    }

    return this.smartyService.searchBy(searchString);
  }

  buildAddress(suggestion) {
    if (this.selectedProvider === 'fastapi') {
      return `${suggestion.street_number || ''} ${suggestion.street_name || ''}, ${suggestion.city}, ${suggestion.state}, ${suggestion.zip_code}`.trim();
    }
    return suggestion.street_line + " " + suggestion.city + ", " + suggestion.state + " " + suggestion.zipcode;
  }

  selectAddress(address) {
    this.form.get('address').setValue(address.full, { emitEvent: false })
    this.getOrCreate();
  }

  handleAddressChange(googleAddress: Address) {
    const formattedAddress = this.residentialService.formatAddress(this.form.get('address').value, googleAddress);
    this.form.get('address').setValue(formattedAddress);
    const county = this.getLongName(this.setAddressPart(googleAddress, 'administrative_area_level_2'));
    const neighborhood = this.getLongName(this.setAddressPart(googleAddress, 'neighborhood'));
    this.form.get('county').setValue(county);
    this.form.get('neighborhood').setValue(neighborhood);
    this.getOrCreate();
  }

  getOrCreate(): void {
    this.form.get('insurable_id').setValue(null);
    this.form.get('address').setErrors(null);
    const form = this.form.value;

    this.residentialService.getOrCreateInsurable(form)
      .subscribe((result: GetOrCreateResponse) =>  {
        const community = result && result.results && result.results[0] ? result.results[0] : null;
        if (community) {
          this.validateStates(community)
          this.setCommunity.emit(community);
        }
        if (community && community.id) {
          this.form.get('insurable_id').setValue(community.id);
        }
        const accountId = community && community.account_id ? community.account_id : null;
        if (accountId) {
          this.setAccountId.emit(accountId);
        }
        this.setBuildings(community);
        this.setUnits(community);
      });
  }

  validateStates(community) {
    const primaryAddress = community && community.primary_address ? community.primary_address : null
    const state = primaryAddress && primaryAddress.state ? primaryAddress.state : null;
    if (state === 'AK' || state === 'HI') {
      this.form.get('address').setErrors({ invalidState: true });
    }
  }

  setAddressPart(googleAddress, part) {
    return googleAddress.address_components.find(ac => (ac.types || []).includes(part));
  }

  getLongName(address) {
    return address && address.long_name ? address.long_name : '';
  }

  setBuildings(community) {
    const buildings = community && community.buildings ? community.buildings : [];
    this.setBuildingsFromAddress.emit(buildings);
  }

  setUnits(community) {
    const units = community && community.units ? community.units : [];
    this.setUnitsFromAddress.emit(units);
  }

  displayFnInsurable(address) {
    return address && address.full ? address.full:
           address ? address : '';
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
